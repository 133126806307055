import propTypes from 'prop-types'
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'
import styles from './index.module.scss'
import { useAnalytics } from '../../../common/analytics'
import { footerData } from './footerData'
import SocialMediaIcon from '../../shared/SocialMediaIcon'
import {
  defaultFooterText,
  spanishFooterText,
  visualizationBarChartFooterText,
  homeVariationFooterText,
  footerTextVariation2,
  footerTextVariation5,
  footerTextVariation3,
  footerCFOSText,
} from '../consts'
import UnCrawlableLink from '../../shared/UnCrawlableLink'
import { isInternalForTealium } from '../../../common/helpers'
import Awards from '../../shared/Awards'
import Hyperlink from '../../shared/HyperLink'
import { APP_BASE_PATH } from 'src/common/constants'
import classNames from 'classnames'
import { useFeatureFlags } from 'src/common/ab-testing/context'
import { experimentSectionByVariation } from 'src/common/analytics/consts'
import { featureToggles } from 'src/common/constants/featureToggles'

const dashboardButtonFont = '@apply text-base font-normal'

const translateData = (homeVariation, cfosDisclousuresVariation) => {
  const isCfosDisclousuresVariationActive =
    cfosDisclousuresVariation === 'control'
  const getFooterText = () => {
    if (isCfosDisclousuresVariationActive) {
      return footerCFOSText
    }

    switch (homeVariation) {
      case 'control':
        return defaultFooterText
      case 'variant-4':
        return visualizationBarChartFooterText
      case 'variant-2':
        return footerTextVariation2
      case 'variant-3':
        return footerTextVariation3
      case 'variant-5':
        return footerTextVariation5
      case 'variant-6':
        return homeVariationFooterText
      case 'control-ny':
      default:
        return defaultFooterText
    }
  }

  return {
    en: {
      cal_privacy_notice_path:
        '/freedom-debt-relief-california-privacy-disclosures#notice-to-california-residents',
      my_personal_information_path: '/do-not-sell-my-personal-information/',
      privacy_path: '/privacy-policy/',
      terms_path: '/terms/',
      privacy_policy: '/freedom-debt-relief-california-privacy-disclosures/',
      footerText: getFooterText(),
    },
    es: {
      cal_privacy_notice_path:
        '/freedom-debt-relief-california-privacy-disclosures-es#actualizaciones-a-estas-divulgaciones-de-ca',
      my_personal_information_path: '/do-not-sell-my-personal-information-es/',
      privacy_path: '/politica-de-privacidad/',
      terms_path: '/terminos-de-servicio/',
      privacy_policy: '/freedom-debt-relief-california-privacy-disclosures-es/',
      footerText: spanishFooterText,
    },
  }
}

const Footer = ({
  footerClassName,
  locale,
  crawlable,
  isHome,
  statusCode,
  homeVariation,
}) => {
  const router = useRouter()
  const { getExperimentVariation } = useFeatureFlags()
  const cfosDisclousuresVariation = getExperimentVariation(
    experimentSectionByVariation[featureToggles.CFOS_DISCLOUSURES]
  )
  const footerParagraph = useMemo(
    () => translateData(homeVariation, cfosDisclousuresVariation)[locale],
    [locale, homeVariation, cfosDisclousuresVariation]
  )

  const { track } = useAnalytics()

  const handleFooterLinkClick = useCallback(
    (href, name) => {
      const clickId = name.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
      const data = {
        nav_link_section: 'Footer',
        click_type: 'Link Click',
        click_id: clickId,
        click_url: href,
        click_text: `FDR-Web | ${name}`,
        event_type: 'track',
        track_event: 'global_footer',
        outbound_url: !isInternalForTealium(href) ? href : '',
      }

      track(data, {}, 'click', statusCode)
    },
    [track, statusCode]
  )

  const handleButtonClick = useCallback(
    (href, name) => {
      const data = {
        nav_link_section: 'Footer',
        click_type: 'Button Click',
        click_id: name,
        click_url: href,
        click_text: `FDR-Web | ${name}`,
        track_event: 'global_footer',
      }

      track(data, {}, 'click', statusCode)
    },
    [track, statusCode]
  )
  const handleSocialIconClick = useCallback(
    (href, name) => {
      const data = {
        nav_link_section: 'Footer',
        click_type: 'Social Media Click',
        click_id: name,
        click_url: href,
        click_text: `FDR-Web | ${name}`,
        track_event: 'global_footer',
      }
      track(data, {}, 'click', statusCode)
    },
    [track, statusCode]
  )

  const handleNonCrawlableLinkClick = useCallback(
    async (item) => {
      handleFooterLinkClick(item.url, `${item.name} - ${item.name}`)
      await router.push(item.url)
    },
    [router, handleFooterLinkClick]
  )

  return (
    <footer
      className={classNames(
        `mx-auto bg-gray-110 px-4 py-12 text-center md:text-left ${footerClassName} w-content`
      )}
      data-testid="footer"
    >
      <div className="mx-auto lg:max-w-screen-lg">
        <div className="w-content flex flex-wrap py-4 text-xs md:pt-0">
          {footerData.map(({ id, subItems }) => (
            <section
              className={`${
                id === 'company'
                  ? 'mb-4 w-full md:mr-0 md:w-[20%] lg:mr-6 lg:w-1/4'
                  : 'mb-4 w-full md:mr-0 md:w-[30%] lg:mr-6 lg:w-1/4'
              }`}
              aria-label="footer-section"
              key={id}
            >
              <ul className="font-normal text-gray-900">
                {Object.keys(subItems).map((item) => {
                  if (locale === 'es' && item === 'espanol') {
                    subItems[item].url = '/'
                    subItems[item].name = 'English'
                  } else if (locale === 'en' && item === 'espanol') {
                    subItems[item].url = '/es/espanol/'
                    subItems[item].name = 'Español'
                  }

                  return (
                    <li className="py-2 lg:py-[5px]" key={item}>
                      {subItems[item].name === 'Unsubscribe' && !crawlable ? (
                        <span
                          className="cursor-pointer text-sm"
                          onClick={() =>
                            handleNonCrawlableLinkClick(subItems[item])
                          }
                        >
                          {subItems[item].name}
                        </span>
                      ) : (
                        <Hyperlink
                          href={subItems[item].url}
                          prefetch={false}
                          childrenClassNames="text-sm"
                          onClick={() =>
                            handleFooterLinkClick(
                              subItems[item].url,
                              subItems[item].name
                            )
                          }
                        >
                          {subItems[item].name}
                        </Hyperlink>
                      )}
                    </li>
                  )
                })}
              </ul>
            </section>
          ))}

          <section
            className="order-first w-full md:order-none md:w-[20%] md:text-right lg:w-2/12"
            role="complementary"
          >
            <section>
              <a
                href={`${APP_BASE_PATH()}/login`}
                rel="noopener"
                target="_blank"
                className={`block ${dashboardButtonFont} mx-auto w-48 border border-blue-560 py-3 text-center text-blue-560 md:mr-0 md:w-40`}
                aria-label="Client Dashboard link"
                onClick={(e) =>
                  handleButtonClick(e.currentTarget.href, 'Client Dashboard')
                }
              >
                Client Dashboard
              </a>
            </section>
            <section>
              <nav role="navigation" aria-label="social-links-menu">
                <div className="my-10 flex items-center justify-center">
                  <SocialMediaIcon
                    id="footer-facebook"
                    type="facebook"
                    url="https://www.facebook.com/pages/Freedom-Debt-Relief/69869352985?ref=hl"
                    onClick={() =>
                      handleSocialIconClick(
                        'https://www.facebook.com/pages/Freedom-Debt-Relief/69869352985?ref=hl',
                        'Facebook'
                      )
                    }
                  />
                  <SocialMediaIcon
                    id="footer-youtube"
                    type="youtube"
                    url="https://www.youtube.com/freedomdebtrelief"
                    onClick={() =>
                      handleSocialIconClick(
                        'https://www.youtube.com/freedomdebtrelief',
                        'Youtube'
                      )
                    }
                  />
                  <SocialMediaIcon
                    id="footer-twitter"
                    type="twitter"
                    url="https://twitter.com/FreedomDebt"
                    onClick={() =>
                      handleSocialIconClick(
                        'https://twitter.com/FreedomDebt',
                        'Twitter'
                      )
                    }
                  />
                  <SocialMediaIcon
                    id="footer-linkedIn"
                    type="linkedin"
                    url="https://www.linkedin.com/company/freedom-debt-relief"
                    onClick={() =>
                      handleSocialIconClick(
                        'https://www.linkedin.com/company/freedom-debt-relieft',
                        'LinkedIn'
                      )
                    }
                  />
                  <SocialMediaIcon
                    id="footer-instagram"
                    type="instagram"
                    className="mr-0"
                    url="https://instagram.com/freedomdebtrelief"
                    onClick={() =>
                      handleSocialIconClick(
                        'https://instagram.com/freedomdebtrelief',
                        'Instagram'
                      )
                    }
                  />
                </div>
              </nav>
            </section>
          </section>
        </div>
        {isHome ? null : (
          <Awards
            statusCode={statusCode}
            className="border-y border-gray-210 pt-12 lg:pb-4 lg:pt-0 xl:pt-4"
            desktopTitleClassname="pt-4 mb-2 xl:pt-0"
          />
        )}
        <div className="w-content flex flex-col py-4 text-left md:flex-row">
          <div className="mb-4 mr-0 text-12">
            <div className={`${styles.disclaimerLink} my-2`}>
              {crawlable ? (
                <>
                  <a
                    className="text-black-base visited:text-black-base hover:text-blue-700"
                    href={footerParagraph.privacy_policy}
                  >
                    Privacy policy
                  </a>
                  <a
                    href={footerParagraph.terms_path}
                    className="mr-1 text-black-base after:mx-[5px] after:content-['\1C0'] visited:text-black-base hover:text-blue-700"
                  >
                    Terms of Use
                  </a>
                  <a
                    href={footerParagraph.cal_privacy_notice_path}
                    className="mr-1 text-black-base after:mx-[5px] after:content-['\1C0'] visited:text-black-base hover:text-blue-700"
                  >
                    California Privacy
                  </a>
                </>
              ) : (
                <>
                  {locale === 'es' ? (
                    <>
                      <UnCrawlableLink
                        className="text-black-base visited:text-black-base hover:text-blue-700"
                        label="Politica de privacidad"
                        href={footerParagraph.privacy_policy}
                      />
                      <UnCrawlableLink
                        className="text-black-base after:mx-[5px] after:content-['\1C0'] visited:text-black-base hover:text-blue-700"
                        label="Terms of Use"
                        href={footerParagraph.terms_path}
                      />
                      <UnCrawlableLink
                        className="text-black-base after:mx-[5px] after:content-['\1C0'] visited:text-black-base hover:text-blue-700"
                        label="California Privacy"
                        href={footerParagraph.cal_privacy_notice_path}
                      />
                    </>
                  ) : (
                    <>
                      <UnCrawlableLink
                        className="text-black-base visited:text-black-base hover:text-blue-700"
                        label="Privacy policy"
                        href={footerParagraph.privacy_policy}
                      />
                      <UnCrawlableLink
                        className="text-black-base after:mx-[5px] after:content-['\1C0'] visited:text-black-base hover:text-blue-700"
                        label="Terms of Use"
                        href={footerParagraph.terms_path}
                      />
                      <UnCrawlableLink
                        className="text-black-base after:mx-[5px] after:content-['\1C0'] visited:text-black-base hover:text-blue-700"
                        label="California Privacy"
                        href={footerParagraph.cal_privacy_notice_path}
                      />
                    </>
                  )}
                </>
              )}
              <UnCrawlableLink
                className="text-black-base visited:text-black-base hover:text-blue-700"
                label={
                  locale === 'es' ? 'Aviso de Privacidad' : 'Privacy Notice'
                }
                href={footerParagraph.privacy_path}
              />
            </div>
            © {new Date().getFullYear()}. All rights reserved. Freedom Debt
            Relief, LLC
          </div>
        </div>
        <p
          className="w-content mb-6 whitespace-pre-line text-left text-12 text-gray-500"
          data-testid="footerText"
        >
          {footerParagraph.footerText}
        </p>
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  footerClassName: '',
  isHome: false,
}

Footer.propTypes = {
  footerClassName: propTypes.string,
  locale: propTypes.string.isRequired,
  crawlable: propTypes.bool.isRequired,
  isHome: propTypes.bool,
  statusCode: propTypes.number,
}

export default Footer
