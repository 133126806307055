import React, { useEffect, useCallback, useState, useRef } from 'react'
import dynamic from 'next/dynamic'
import propTypes from 'prop-types'
import { renderable } from 'src/common/propTypes'
import Footer from './Footer'
import styles from './index.module.scss'
import { useAnalytics } from '../../common/analytics'
import CustomJsonLd from '../shared/CustomJsonLd'
import { reviewsJsonLdData } from 'src/common/constants/reviewsJsonLd'
import { useFeatureFlags } from 'src/common/ab-testing/context'
import { featureToggles } from 'src/common/constants/featureToggles'
import { experimentSectionByVariation } from 'src/common/analytics/consts'
import { useLeadId } from 'src/common/lead-id'
import axios from 'axios'
import { useTrustStripsData } from 'src/common/hooks/useTrustStripsData'

const ActualHeader = dynamic(() => import('./ActualHeader'))

const Layout = ({
  children,
  footerClassName,
  withHeaderTel = false,
  locale = 'en',
  isHome,
  isSalesPage = false,
  crawlable,
  isBrandPage,
  isFullBleed,
  btnClassName,
  statusCode,
  renderReviewJsonLd,
  homeVariation,
  isArticleOrBlogPage = false,
  isPzn,
  trustPilotData = {},
}) => {
  const [sideBarOpen, setSideBarOpen] = useState(false)
  const [sideBarInit, setSideBarInit] = useState(false)
  const leadId = useLeadId()
  const { track, view } = useAnalytics()
  const trustStripsData = useTrustStripsData()
  const { setVariations } = useFeatureFlags()
  const pageLoaded = useRef(false)

  const handleClick = useCallback(() => {
    setSideBarOpen(!sideBarOpen)
    setSideBarInit(true)
    const data = {
      nav_link_section: 'Header',
      click_type: 'Menu Click',
      click_id: 'Menu',
      click_text: 'FDR-Web | Menu',
      track_event: 'global_header',
    }
    track(
      data,
      {
        event_type: 'track',
      },
      'click',
      statusCode
    )
  }, [sideBarOpen, track, statusCode])

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0]
    sideBarOpen
      ? body.classList.add('h-screen', 'overflow-hidden')
      : body.classList.remove('h-screen', 'overflow-hidden')
  }, [sideBarOpen])

  const setViewExperiments = useCallback(async () => {
    const experiments = []

    try {
      const flags = []

      // Homepage drop down floating footer
      if (isHome) flags.push(featureToggles.HOMEPAGE_DROP_DOWN_FLOATING_FOOTER)

      // CFOS disclosures
      if (isHome) flags.push(featureToggles.CFOS_DISCLOUSURES)

      // Consent banner experiment
      flags.push(featureToggles.CONSENT_BANNER)

      if (isArticleOrBlogPage)
        flags.push(featureToggles.ARTICLES_AND_BLOGS_BOTTOM_CTA)

      const response = await axios.post('/api/ab-testing/', {
        flags,
        leadId: leadId,
      })

      const featuresFlag = response.data?.features || {}

      // Homepage drop down floating footer
      if (isHome) {
        experiments.push({
          experimentSection:
            experimentSectionByVariation[
              featureToggles.HOMEPAGE_DROP_DOWN_FLOATING_FOOTER
            ],
          variation:
            featuresFlag[featureToggles.HOMEPAGE_DROP_DOWN_FLOATING_FOOTER] ||
            'control',
        })
      }

      // CFOS disclosures
      if (isHome)
        experiments.push({
          experimentSection:
            experimentSectionByVariation[featureToggles.CFOS_DISCLOUSURES],
          variation: featuresFlag[featureToggles.CFOS_DISCLOUSURES],
        })

      // Consent banner experiment
      experiments.push({
        experimentSection:
          experimentSectionByVariation[featureToggles.CONSENT_BANNER],
        variation: featuresFlag[featureToggles.CONSENT_BANNER],
      })

      if (isArticleOrBlogPage) {
        experiments.push({
          experimentSection:
            experimentSectionByVariation[
              featureToggles.ARTICLES_AND_BLOGS_BOTTOM_CTA
            ],
          variation:
            featuresFlag[featureToggles.ARTICLES_AND_BLOGS_BOTTOM_CTA] ||
            'control',
        })
      }

      if (isHome && homeVariation === 'control') {
        experiments.push({
          experimentSection:
            experimentSectionByVariation[featureToggles.TRUST_STRIPS],
          variation: trustStripsData.variation?.toLowerCase() || 'control',
        })
      }
    } catch (err) {
      if (isArticleOrBlogPage) {
        experiments.push({
          experimentSection:
            experimentSectionByVariation[
              featureToggles.ARTICLES_AND_BLOGS_BOTTOM_CTA
            ],
          variation: 'control',
        })
      }

      if (isHome && homeVariation === 'control') {
        experiments.push({
          experimentSection:
            experimentSectionByVariation[featureToggles.TRUST_STRIPS],
          variation: 'control',
        })
      }
    }

    pageLoaded.current = true
    setVariations(experiments)
    view(statusCode, {}, null, experiments)
  }, [
    homeVariation,
    leadId,
    setVariations,
    view,
    statusCode,
    isArticleOrBlogPage,
    isHome,
    isPzn,
  ])

  useEffect(() => {
    if (pageLoaded.current) return
    setViewExperiments()
  }, [setViewExperiments])

  return (
    <>
      {renderReviewJsonLd ? (
        <CustomJsonLd id="review" data={reviewsJsonLdData()} />
      ) : null}
      <div
        className={`${styles.layout} ${
          sideBarInit ? (sideBarOpen ? styles.menuOpen : styles.menuClosed) : ''
        }`}
      >
        <div className={styles.site}>
          <div className={`flex justify-start lg:justify-center print:hidden`}>
            <ActualHeader
              sideBarOpen={sideBarOpen}
              setSideBarOpen={setSideBarOpen}
              withHeaderTel={withHeaderTel}
              locale={locale}
              btnClassName={btnClassName}
              statusCode={statusCode}
              isHome={isHome}
              isSalesPage={isSalesPage}
              isArticleOrBlogPage={isArticleOrBlogPage}
              isBrandPage={isBrandPage}
              trustPilotData={trustPilotData}
              handleSideNavClick={handleClick}
            />
          </div>
          <main className={'mt-20 lg:mt-0'}>{children}</main>
          <Footer
            footerClassName={footerClassName}
            locale={locale}
            crawlable={crawlable}
            isHome={isHome}
            isBrandPage={isBrandPage}
            statusCode={statusCode}
            isFullBleed={isFullBleed}
            homeVariation={homeVariation}
          />
        </div>
      </div>
    </>
  )
}

Layout.defaultProps = {
  locale: 'en',
  footerClassName: '',
  withHeaderTel: true,
  crawlable: false,
  isBrandPage: false,
  isHome: false,
  isArticleOrBlogPage: false,
  isFullBleed: false,
  btnClassName: undefined,
  renderReviewJsonLd: true,
  experiments: [],
  isPzn: false,
  iSalesHeader: false,
  trustPilotData: {},
}

Layout.propTypes = {
  locale: propTypes.string,
  withHeaderTel: propTypes.bool,
  children: renderable.isRequired,
  footerClassName: propTypes.string,
  crawlable: propTypes.bool,
  isBrandPage: propTypes.bool,
  isHome: propTypes.bool,
  isFullBleed: propTypes.bool,
  isArticleOrBlogPage: propTypes.bool,
  btnClassName: propTypes.string,
  statusCode: propTypes.number,
  renderReviewJsonLd: propTypes.bool,
  experiments: propTypes.array.isRequired,
  isPzn: propTypes.bool,
  iSalesHeader: propTypes.bool,
}

export default Layout
